<div>
  <div class="row empty-line">
    <!-- <div *ngIf="!isRegister" class="col-sm-12 col-md-12 align-center">
      <p class="hint-text">{{ 'LoginSocialMediaAccount' | resourcekey }}</p>
    </div> -->
  </div>
  <div class="row margin-right">
    <!-- <div class="row align-center"> -->
      <!-- <div class="row facebook social-btn align-center">
        <a *ngIf="isRegister" (click)="signInWithFacebook()">
          <img [src]="'assets/svg-icons/facebook_.svg'" alt="facebook" />
          {{ 'Sign_Up_With_Facebook' | resourcekey }}
        </a>
        <a *ngIf="!isRegister" (click)="signInWithFacebook()">
          <img [src]="'assets/svg-icons/facebook_.svg'" alt="facebook" />
          {{ 'ContinueWithFacebook' | resourcekey }}
        </a>
      </div> -->
    <!-- </div> -->

    <!-- <div class="row align-center"> -->
      <div class="row microsoft social-btn align-center">
        <a *ngIf="isRegister" (click)="signInWithMicrosoft()">
          <img [src]="'assets/svg-icons/microsoft_.svg'" alt="microsoft" />
          {{ 'Sign_Up_With_Outlook' | resourcekey }}
        </a>
        <a *ngIf="!isRegister" (click)="signInWithMicrosoft()">
          <img [src]="'assets/svg-icons/microsoft_.svg'" alt="microsoft" />
          {{ 'ContinueWithMicrosoft' | resourcekey }}
        </a>
      </div>
    <!-- </div> -->

    <!-- <div class="row align-center"> -->
      <div class="row google social-btn align-center" id="google-button" [ngStyle]="showRegister == true && {'margin-left': '-18px'}">
      </div>
    <!-- </div> -->

    <!-- <div class="row align-center">
        <div class="my-awesome-button" onclick="handleGoogleLogin()">
          Login with new Google Sign-in</div>
      </div> -->

    <!-- <div class="row align-center">
    <div class="nhs social-btn">
      <a>
        <img [src]="'assets/svg-icons/nhs.svg'" alt="nhs"/> Continue with NHS
      </a>
    </div>
    </div> -->
  </div>
  <!--
<script>
  const createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement("div");
    // Or you can simple hide it in CSS rule for custom-google-button
    googleLoginWrapper.style.display = "none";
    googleLoginWrapper.classList.add("custom-google-button");

    // Add the wrapper to body
    document.body.appendChild(googleLoginWrapper);

    // Use GSI javascript api to render the button inside our wrapper
    // You can ignore the properties because this button will not appear
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: "icon",
      width: "200",
    });

    const googleLoginWrapperButton =
      googleLoginWrapper.querySelector("div[role=button]");

    return {
      click: () => {
        googleLoginWrapperButton.click();
      },
    };
  };

  // Now we have a wrapper to click
  const googleButtonWrapper = createFakeGoogleWrapper();
  // ...
  // ...
  // ...
  window.handleGoogleLogin = () => {
    // Use wrapper click to prevent Illegal invocation exception
    googleButtonWrapper.click();
    // This will open GSI login and after success you will have
    // a response on googleLoginCallback method previously created
  };
</script> -->
