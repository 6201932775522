import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicesModule } from '../services.module';
import { CampaignTypeEnum } from 'src/app/shared/enums/campaign-type.enum';
import { NewUserSubmissionByRelation } from 'src/app/features/parent-portal/shared/models/new-user-submission-byrelation.model';
import { PortalUserAccount } from 'src/app/features/parent-portal/my-account-page/portal-user-account.model';
import { UserProfileModel } from 'src/app/features/screener/user-profile/user-profile.model';
import { AdminUserModel } from 'src/app/features/admin/admin-user.model';
import { userAssignedRight } from 'src/app/shared/models/userAssignedRight.model';
import { userModuleAccess } from 'src/app/shared/models/user-module-access.model';
import { UserBookingSlotModel } from 'src/app/features/parent-portal/shared/models/user-booking-slot.model';
import { PublicParentDetails } from 'src/app/features/public-parent-page/public-parent-details.model';
import { NewEmptyUserSubmissionByRelation } from 'src/app/features/parent-portal/shared/models/new-empty-user-submission-byrelation';
import { ChangeEmailModel } from 'src/app/shared/models/change-email.model';
import { ChangePasswordModel } from 'src/app/shared/models/change-password.model';

@Injectable({
  providedIn: ServicesModule
})

export class HttpUserService {

  constructor(private http: HttpClient) { }

  getChildrenDetails() {
    return this.http.get('api/parent/getchildren/details');
  }

  getChildCampaignsList(userRelationshipId: string) {
    return this.http.get(`api/parent/child/campaigns/${userRelationshipId}`);
  }

  getTenantCampaignsList(tenantCode: string) {
    return this.http.get(`api/parent/tenant/campaigns/${tenantCode}`);
  }

  getChildSubmissionsList(userRelationshipId: string) {
    return this.http.get(`api/parent/child/submissions/${userRelationshipId}`);
  }

  getSubmissionDialogDetails(campaignId: string) {
    return this.http.get(`api/submission/dialog/details/${campaignId}`);
  }

  matchSubmissionWithSelectedChild(submissionId: string, childId: string, campaignType: CampaignTypeEnum) {
    return this.http.post(`api/submission/${submissionId}/match/selected/child/${childId}/${campaignType}`, null);
  }

  updateSubmissionSchool(submissionId: string, schoolId: string, campaignType: CampaignTypeEnum, isVaccineAdministrationUpdate: boolean = false) {
    return this.http.post(`api/submission/${submissionId}/update/school/${schoolId}/${campaignType}/${isVaccineAdministrationUpdate}`, null);
  }

  setSubmissionToProgressState(submissionId: string, campaignType: CampaignTypeEnum) {
    return this.http.post(`api/submission/${submissionId}/setToProgressState/${campaignType}`, null);
  }

  createNewUserSubmissionByRelation(data: NewUserSubmissionByRelation) {
    return this.http.post(`api/campaign/submission/newbyrelation`, data);
  }

  createEmptyNewUserSubmissionByRelation(data: NewEmptyUserSubmissionByRelation) {
    return this.http.post(`api/tenant/submission/newbyrelation`, data);
  }

  createPublicParentUserAndSubmission(data: PublicParentDetails) {
    return this.http.post(`api/public-parent/createUserAndSubmission`, data);
  }

  getCampaignDetailsById(campaignId: string) {
    return this.http.get(`api/campaign/details/${campaignId}`);
  }

  getUserAccount() {
    return this.http.get('api/user/account');
  }

  updateUserAccount(userAccount: PortalUserAccount) {
    return this.http.post('api/user/account', userAccount);
  }

  hasUserCampaignInvitations() {
    return this.http.get('api/user/check/campaign-invitations');
  }

  getUserCampaignInvitations() {
    return this.http.get('api/user/campaign-invitations');
  }

  getBookingSlotsByCampaignId(campaignId: string) {
    return this.http.get(`api/user/campaign/${campaignId}/booking-slots`);
  }

  getBookingSlotsByCampaignIdCheckCapacity(campaignId: string) {
    return this.http.get(`api/user/campaign/${campaignId}/booking-slots-capacity-check`);
  }

  addUserBookingSlot(userBooking: UserBookingSlotModel) {
    return this.http.post('api/user/add/booking-slot/campaign', userBooking);
  }

  editUserBookingSlot(campaignType: CampaignTypeEnum, submissionId: string, bookingSlotId: string) {
    return this.http.post(`api/user/edit/booking-slot/campaign/${campaignType}/submission/${submissionId}/${bookingSlotId}`, null);
  }

  hasUserCompletedDetailsForSelfCompletion() {
    return this.http.get('api/user/ready/self-completion');
  }

  getTenantCountryCodeBySubmission(campaignType: CampaignTypeEnum, submissionId: string) {
    return this.http.get(`api/user/tenant/country-code/submission/${campaignType}/${submissionId}`);
  }

  getUnreadParentMessages() {
    return this.http.get('api/sharedDocuments/getUnreadParentMessages');
  }

  getUnreadParentMessagesAndLettersCount() {
    return this.http.get('api/parent/getUnreadParentMessagesAndLettersCount');
  }

  getTenantMedia() {
    return this.http.get('api/parent/tenant/tenantMedia');
  }

  getTenantMediaByTenantId(tenantId: string) {
    return this.http.get(`api/tenant/tenantMedia/${tenantId}`);
  }

  setMessageAsRead(sharedDocumentSourceId: string) {
    return this.http.post(`api/sharedDocuments/setMessageAsRead/${sharedDocumentSourceId}`, null);
  }

  setLetterAsRead(childPdfId: string) {
    return this.http.post(`api/letters/setLettersAsRead/${childPdfId}`, null);
  }

  getSharedDocumentUploads(sharedDocumentSourceId: string) {
    return this.http.get(`api/sharedDocuments/getSharedDocumentUploads/${sharedDocumentSourceId}`);
  }

  getYearGroups() {
    return this.http.get('api/children/getYearGroups');
  }

  getYearGroupsForCountryCode(countryCode: string) {
    return this.http.get(`api/children/getYearGroupsForCountryCode/${countryCode}`);
  }

  getUserDetails() {
    return this.http.get('api/user/details');
  }

  saveUserDetails(userDetails: UserProfileModel) {
    return this.http.post('api/user/edit', userDetails);
  }

  getCampaignPortalWithSchools(campaignPortalCode: string) {
    return this.http.get(`api/campaignPortalWithSchools/${campaignPortalCode}`);
  }

  getCampaignPortalWithSchool(campaignPortalCode: string, schoolCode: string) {
    return this.http.get(`api/campaignPortalWithSchool/${campaignPortalCode}/${schoolCode}`);
  }

  checkBookingSlotCapacity(bookingslotId: string, isImms: boolean) {
    return this.http.get(`api/checkBookingSlotCapacity/${bookingslotId}/${isImms}`);
  }

  getAccessRights() {
    return this.http.get(`api/User/GetAccessRights`);
  }

  getUserAndAccessRightsAndModuleAvailability(userId: string) {
    return this.http.get(`api/User/GetUserAndAccessRightsAndModuleAvailability/${userId}`);
  }

  updateUsersAssignedOrganizationAndSchools(adminUserModel: AdminUserModel) {
    return this.http.post('api/account/updateUsersAssignedOrganizationAndSchools', adminUserModel);
  }

  updateUserDetails(adminUserModel: AdminUserModel) {
    return this.http.post('api/account/updateUserDetails', adminUserModel);
  }

  updateUsersAccessRights(adminUserModel:AdminUserModel, userAssignedRights: userAssignedRight[], userModuleAccesses: userModuleAccess[]) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      "api/account/updateUsersAccessRights",
      { adminUserModel, userAssignedRights, userModuleAccesses },
      options
    );
  }

  getTenantLogosByTenantCodes(tenCodeList: string[]) {
    return this.http.post('api/GetTenantLogosByTenantCodes', tenCodeList);
  }

  getEmailFrom() {
    return this.http.get(`api/parent/getEmailFrom`);
  }

  getIsParentMFAEnabled() {
    return this.http.get(`api/parent/getIsParentMFAEnabled`);
  }

  getUserEmailById(userId: string) {
    return this.http.get(`api/parent/getUserEmailById/${userId}`);
  }

  changeEmail(changeEmailModel: ChangeEmailModel) {
    return this.http.post('api/parent/changeEmail', changeEmailModel);
  }

  changePassword(changePasswordModel: ChangePasswordModel) {
    return this.http.post('api/parent/changePassword', changePasswordModel);
  }
}
