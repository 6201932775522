<div class="dialog-container">
  <div class="top-bar">
    <span class="span">{{ "Common_Preview" | resourcekey }} </span>

    <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    <!-- <button
    mat-raised-button
    color="primary"
    (click)="closeDialog()"
    class="close-button"
  >
    {{ "Common_Close" | resourcekey }}
  </button> -->
  </div>
  <div>
    <span class="ref" *ngIf="data.type == PreviewFileEnum.Message">REF: {{data?.message?.id}} {{ 'MessagePreviewText' | resourcekey | stringFormat: { "0": data?.message?.childMessage?.messageSourceCount } }} </span>
  </div>
  <div class="preview-container">
    <pdf-preview class="pdf-preview"
      *ngIf="data.type == PreviewFileEnum.Pdf"
      [isLocalFile]="data.isLocalFile"
      [pdf]="source"
    ></pdf-preview>

    <image-preview
      *ngIf="data.type == PreviewFileEnum.Image"
      [isLocalFile]="data.isLocalFile"
      [image]="source"
    ></image-preview>

    <letter-preview
      *ngIf="data.type == PreviewFileEnum.HtmlLetter"
      [html]="source"
    ></letter-preview>


    <message-preview
      *ngIf="data.type == PreviewFileEnum.Message"
      [message]="data.message"
      [content]="source"
      [title]="data.message.letterType"
      [sharedDocumentSourceId]="data.message.childMessage?.sharedDocumentSourceId"
      [isFromNotification]="false"
    ></message-preview>

  </div>
</div>
